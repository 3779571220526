import axiosInstance from "@/utils/axiosInstance"
import { queryOptions } from "@tanstack/react-query"

export interface User {
  id: string // TMNID
  orgId: string
  account: string // Latitude account
  metadata: Record<string, any> // Other user metadata
  lastAccessAt?: Date // Optional LastAccessAt property
}

export async function fetchProfile() {
  try {
    const res = await axiosInstance.get<User>("/users/profile")
    return res.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const profileQueryOptions = queryOptions({
  queryKey: ["profile"],
  queryFn: fetchProfile,
  refetchOnWindowFocus: false
})

export async function updateMetadata(metadata: Record<string, any>) {
  try {
    const res = await axiosInstance.post(`/users/metadata`, metadata)
    return res.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function fetchIsProfileCreated() {
  try {
    const res = await axiosInstance.get<User>("/user/is-profile-created")
    return res.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const isProfileCreatedQueryOptions = queryOptions({
  queryKey: ["isProfileCreated"],
  queryFn: fetchIsProfileCreated,
  enabled: true,
  retry: 9, // will call API for 10 times (9 retry + 1 initial call)
  retryDelay: 1000
})
