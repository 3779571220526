import { QueryClient, useQuery, useSuspenseQuery } from "@tanstack/react-query"
import { Outlet, createRootRouteWithContext } from "@tanstack/react-router"
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
// import { TanStackRouterDevelopmentTools } from "@/components/TanStackRouterDevelopmentTools";
import {
  Suspense,
  useEffect,
  useState
  // lazy
} from "react"
import AuthProvider from "@/components/AuthProvider"

import "@/utils/i18n"
import { balanceQueryOptions } from "@/api/common.api"
import { profileQueryOptions } from "@/api/users.api"
import { AppConfigs, appConfigsQueryOptions } from "@/api/appConfigs.api"

//components
import { Toaster } from "@/components/ui/toaster"
import useAppConfigStore from "@/stores/useAppConfigStore"
import { initMixpanel } from "@/utils/MixpanelConfig"
import { ErrorPage } from "./_fullscreen-layout/error/route.lazy"
import RequestError from "@/utils/RequestError"
import { initBraze } from "@/utils/BrazeConfig"
import { initFaro } from "@/utils/FaroConfig"

const setAppConfig = ({
  data,
  setAppVersion,
  setIsEnableChallenge,
  setIsEnableTrackingDebug,
  setAssetUrl,
  setMediaUrl
}: {
  data: AppConfigs | undefined
  setAppVersion: (appVersion: string) => void
  setIsEnableChallenge: (isEnableChallenge: boolean) => void
  setIsEnableTrackingDebug: (isEnableTrackingDebug: boolean) => void
  setAssetUrl: (assetUrl: string) => void
  setMediaUrl: (mediaUrl: string) => void
}) => {
  if (data?.faroToken) {
    initFaro(data?.faroToken)
  }

  const isEnableTrackingDebug = data?.IsEnableTrackingDebug ?? false
  const mixpanelToken = data?.mixpanelToken
  if (mixpanelToken?.projectToken) {
    initMixpanel(mixpanelToken, isEnableTrackingDebug)
  }
  const brazeToken = data?.brazeToken
  if (brazeToken?.apiKey) {
    initBraze(brazeToken, isEnableTrackingDebug)
  }
  if (data?.version) {
    setAppVersion(data.version)
  }
  setIsEnableChallenge(data?.isEnableChallenge ?? false)
  setIsEnableTrackingDebug(isEnableTrackingDebug)
  setAssetUrl(data?.assetUrl ?? "")
  setMediaUrl(data?.mediaUrl ?? "")
}

export const Route = createRootRouteWithContext<{
  queryClient: QueryClient
}>()({
  component: RootComponent,
  loader: ({ context: { queryClient } }) =>
    queryClient?.ensureQueryData(balanceQueryOptions),
  onError(err) {
    console.error("RootComponent onError:", err)
  },
  errorComponent: ({ error, info }) => {
    const [isReady, setIsReady] = useState(false)

    useEffect(() => {
      setIsReady(true)
    }, [])
    useEffect(() => {
      console.error("RootComponent errorComponent:", error, info)
    }, [error, info])
    if (isReady && error instanceof RequestError) {
      // Show error page with custom message when mixpanel is ready
      return <ErrorPage code={error.errorCode} message={error.errorMessage} />
    }
    // Show default error page when tracking is not ready
    // This mean the error page will not fire tracking event
    return <ErrorPage />
  }
})

function RootComponent() {
  const [isReady, setIsReady] = useState(false)
  const { data: userProfile, isLoading: isUserProfileLoading } =
    useSuspenseQuery(profileQueryOptions)
  const { data: balanceData, isLoading: balanceIsLoading } =
    useSuspenseQuery(balanceQueryOptions)
  const { data, isLoading: appConfigIsLoading } = useQuery(appConfigsQueryOptions)
  const {
    setAppVersion,
    setIsEnableChallenge,
    setIsEnableTrackingDebug,
    setAssetUrl,
    setMediaUrl,
    setUser
  } = useAppConfigStore()

  useEffect(() => {
    setAppConfig({
      data,
      setAppVersion,
      setIsEnableChallenge,
      setIsEnableTrackingDebug,
      setAssetUrl,
      setMediaUrl
    })
    setUser({
      id: balanceData.balanceResponseData.userID,
      metadata: userProfile.metadata
    })
    setIsReady(true)
  }, [data, appConfigIsLoading])

  if (!isReady || balanceIsLoading || appConfigIsLoading || isUserProfileLoading) {
    return null
  }

  return (
    // Importance!! This suspense is for the TanStackRouterDevelopmentTools and react-i18next to load the translation files(aka. namespaces)
    <Suspense fallback={null}>
      <AuthProvider>
        <Outlet />
        {/* <ReactQueryDevtools buttonPosition="top-right" />
        <TanStackRouterDevelopmentTools position="bottom-right" /> */}
        <Toaster />
      </AuthProvider>
    </Suspense>
  )
}
