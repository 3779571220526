import { createFileRoute } from "@tanstack/react-router"
import StopwatchIcon from "@/assets/icons/ic_stopwatch.svg?react"
import TrophyIcon from "@/assets/icons/ic_trophy.svg?react"
import LockIcon from "@/assets/icons/ic_lock_24.svg?react"
import { Challenge } from "@/api/challenge.api"
import { Button } from "@/components/ui/button"
import useAppConfigStore from "@/stores/useAppConfigStore"
export const Route = createFileRoute(
  "/_main-layout/challenge-detail/$challengeDetailId/~components/challengeRewardCard"
)({
  component: () => <div>Hello MissionCard!</div>
})

const ChallengeRewardCard = ({ challenge }: { challenge: Challenge }) => {
  const { assetUrl } = useAppConfigStore()

  const rewards = challenge?.campaign?.rewards?.[0]
  const totalTasks = challenge?.tasks?.length || 0
  const completedTasks = challenge?.tasks?.filter(task => task.completed).length

  const isClaimable = completedTasks > 0
  const isCompleted = totalTasks > 0 && completedTasks === totalTasks
  return (
    <div className="relative w-auto overflow-hidden m-4">
      {/* Separate Header */}
      <div className="relative flex justify-between items-center px-4 py-3 bg-green-100 rounded-2xl">
        <div
          data-testid="challenge-svg"
          className="absolute inset-0 w-full h-full rounded-2xl"
          style={{
            backgroundImage: `url(/images/bg-header-challenge-reward-card.svg)`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover"
          }}
        ></div>

        <div className="relative z-10 flex items-center space-x-2">
          <TrophyIcon className="w-8 h-8 text-yellow-400" />
          <span
            className="ml-2 text-sm font-medium text-gray-800"
            style={{
              color: "var(--text-light-primary, #212B36)",
              fontFamily: "IBM Plex Sans Thai, sans-serif",
              lineHeight: "16px"
            }}
          >
            {isCompleted
              ? "Complete all missions and claim to unlock!"
              : "Reward is ready to be claimed!"}
          </span>
        </div>
      </div>

      {/* Content Box */}
      <div className=" p-4 items-center rounded-2xl bg-white border-t border-gray-200">
        <div className="flex">
          <div className="relative flex-shrink-0">
            <div className="absolute left-[10px] w-[100px] h-[100px] bg-white rounded-full -translate-y-9"></div>
            <div className="ml-4 w-[88px] h-[88px] bg-gray-200 rounded-full flex items-center justify-center border border-gray-300 transform -translate-y-7">
              <img
                src={`${assetUrl}${challenge?.campaign?.image}`}
                className="w-full h-full rounded-full"
              />
            </div>
          </div>

          {rewards && (
            <div className="flex flex-col items-end ml-5">
              <p className="mt-[-10px] text-sm font-semibold text-[#212B36] leading-6 font-['IBM Plex Sans Thai', sans-serif]">
                {rewards?.description}
              </p>
            </div>
          )}
        </div>

        <div className="flex  justify-between items-center">
          {/* Voucher Text in the Bottom Left */}
          <div>
            <span className="flex items-center space-x-1 text-sm text-gray-500">
              <StopwatchIcon className="full h-full" />
              <span>Voucher</span>
            </span>
          </div>

          {/* Button on the right */}

          {isCompleted ? (
            <Button
              size="sm"
              className="bg-[#FF6636] text-white hover:bg-gray-100 space-x-1 rounded-lg"
            >
              Claim
            </Button>
          ) : (
            <button
              className={`flex items-center space-x-1 rounded-md px-4 py-2 text-sm font-medium ${
                isClaimable
                  ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                  : "bg-blue-600 text-white cursor-pointer"
              }`}
              // onClick={onClaimClick}
              disabled={isClaimable}
            >
              <div className="flex items-center gap-2">
                <LockIcon className="w-[18px] h-[18px]" />
                <span>Claim</span>
              </div>
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default ChallengeRewardCard
